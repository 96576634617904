<template>
  <el-dialog :title="$t('condominiumList.findMeterDialog.title')" v-model="showDialog" :close-on-click-modal="false">
    <!--    <el-alert v-if="showAlert" type="warning" :title="$t('condominium.readings.printReadingsDialog.alert')" :closable="false"></el-alert>-->
    <h3>Inserisci matricola</h3>

    <div class="search-container">
      <el-input placeholder="Numero matricola" v-model="serialNumber"></el-input>
      <div class="search-btn-container">
        <el-button icon="el-icon-search" @click="findSerialNumber" style="padding: 0 18px"></el-button>
      </div>
    </div>


    <el-card class="box-card" v-if="meterInfo">
      <el-descriptions v-if="meterInfo.found && meterInfo.isOwner" class="margin-top table-fixed"
                       :title="'Info Appartamento - '+getFullName(meterInfo.apartment.user)" :column="3"
                       direction="vertical" border>
        <el-descriptions-item v-for="apartment in apartmentDescriptionFields" :key="apartment.field"
                              :span="apartment.span">
          <template #label>
            <div style="display: flex; align-items: flex-end">
              <span>
                <i :class="apartment.icon"></i> {{ apartment.label }}
              </span>
            </div>
          </template>
          {{ apartment.value }}
        </el-descriptions-item>
      </el-descriptions>
      <div v-else-if="meterInfo.found">
        Questo contatore non appartiene ad un tuo condominio.<br>
        <strong>Owner contatore:</strong> <br>
        {{getFullName(meterInfo.apartment.condominium.owner)}}
        {{meterInfo.apartment.condominium.email}}
      </div>
      <div v-else>
        Matricola non trovata<br>
      </div>
    </el-card>



    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dismiss"> {{ $t("buttons.close") }} </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UserMixin from "../../mixins/UserMixin";
import AddressMixin from "../../mixins/AddressMixin";

export default {
  name: "FindSerialNumberDialog",
  mixins: [UserMixin, AddressMixin],
  emits: ["submit", "dismiss"],
  data() {
    return {
      showDialog: false,
      serialNumber: null,
      meterInfo: null
    }
  },
  computed: {
    ...mapGetters([
      "currentMeter",
    ])
  },
  methods: {
    ...mapActions(["fetchMeterBySerial"]),
    dismiss() {
      this.$emit("dismiss");
      this.showDialog = false;
    },
    show() {
      this.showDialog = true;
    },
    async findSerialNumber() {
      await this.fetchMeterBySerial(this.serialNumber)
      this.meterInfo = {...this.currentMeter};
      this.createFields()
    },
    createFields() {

      this.apartmentDescriptionFields = [
        {
          field: "condominium",
          value: this.stringifyAddress(this.meterInfo.apartment.condominium.address),
          label: "Indirizzo condominio - " + this.meterInfo.apartment.condominium.description,
          icon: "el-icon-location-outline",
          span: 2,
        }, {
          field: "user",
          value: this.getFullName(this.meterInfo.apartment.user),
          label: "Utente",
          icon: "el-icon-user",
          span: 1,
        }, {
          field: "floor",
          value: this.meterInfo.apartment.floor,
          label: "Piano/Scala",
          icon: "el-icon-user",
          span: 1,
        }, {
          field: "number",
          value: this.meterInfo.apartment.number,
          label: "Numero",
          icon: "el-icon-user",
          span: 1,
        }, {
          field: "kApartment",
          value: this.meterInfo.apartment.kApartment,
          label: "Valore K",
          icon: "el-icon-message",
          span: 1,
        },
        {
          field: "condominiumAdministrator",
          value: this.getFullName(this.meterInfo.apartment.condominium.condominiumAdministrator),
          label: "Amministratore Condominiale",
          icon: "el-icon-user",
          span: 1,
        },
        {
          field: "description",
          value: this.meterInfo.apartment.description,
          label: "Descrizione",
          icon: "el-icon-message",
          span: 2,
        },
      ];

    }
  }
}
</script>

<style scoped>
.search-container {
  display: flex;
  padding-bottom: 1em;
}

.search-btn-container {
  margin-left: 5px;
}

</style>
