<template>
  <div>
    <el-row justify="space-between">
      <el-col :span="8" style="display: flex; align-items: center; padding-bottom: 14px">
        <div>
          <el-button type="primary" icon="el-icon-search" @click="openFindSerialNumberDialog">Cerca matricola</el-button>
        </div>
        <div style="padding-left: 14px">
        <el-button v-if="isGranted('ROLE_MANAGER')" type="primary" icon="el-icon-plus"
                   @click="openCreateCondominiumDialog">Crea Condominio
        </el-button>
        </div>
        <div style="padding-left: 14px">
          <el-switch
              v-if="isGranted('ROLE_MANAGER')"
              v-model="activeView"
              active-text="Visualizza solo attivi"
              inactive-text=""
          >
          </el-switch>
        </div>

      </el-col>
      <el-col :span="8">
        <el-input placeholder="Cerca per denominazione" v-model="query" @clear="clearInput" clearable></el-input>
      </el-col>
    </el-row>
    <hr>
    <el-row>
      <el-col v-if="condominia">
        <el-table
            :data="condominia.filter( (condo) =>
          (!activeView || condo.active) && (!query || condo.description.toLowerCase().includes(query.toLowerCase()))
        )">
          <!-- <el-table-column prop="id" label="ID" width="50"></el-table-column> -->
          <el-table-column prop="description" label="Denominazione"></el-table-column>
          <el-table-column prop="address" label="Indirizzo">
            <template #default="scope">
              {{ stringifyAddress(scope.row.address) }}
            </template>
          </el-table-column>
          <el-table-column v-if="!condoAdmin" prop="owner" label="Cliente Sunny">
            <template #default="scope">
              {{ getFullName(scope.row.owner) }}
            </template>
          </el-table-column>
          <el-table-column v-if="!condoAdmin" prop="condominiumAdministrator" label="Amm. Cond.">
            <template #default="scope">
              {{ getFullName(scope.row.condominiumAdministrator) }}
            </template>
          </el-table-column>
          <el-table-column v-if="!condoAdmin" prop="supplier" label="Gestore Calore/Acqua">
            <template #default="scope">
              {{ getFullName(scope.row.supplier) }}
            </template>
          </el-table-column>
          <el-table-column v-if="!condoAdmin" prop="serviceTechnician" label="Ditta Ass. Tecn.">
            <template #default="scope">
              {{ getFullName(scope.row.serviceTechnician) }}
            </template>
          </el-table-column>
          <el-table-column v-if="!condoAdmin" prop="active" label="Stato" width="70">
            <template #default="scope">
              <i :class="getActiveIcon(scope.row.active)"></i>
            </template>
          </el-table-column>
          <el-table-column width="70">
            <template #default="scope">
              <el-tooltip class="item" effect="dark"
                          content="Clicca per visualizzare la lista degli appartamenti e i consumi">
                <el-button @click="condominiumSelected(scope.row)" type="primary" circle
                           size="small" icon="el-icon-d-arrow-right"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <form-dialog v-if="isGranted('ROLE_MANAGER') " :title="'Crea Condominio'" :fields="newCondominiumFields"
                 footerNotes="I campi con l'asterisco sono obbligatori"
                 @submit="submittedCreateCondominium"
                 ref="createCondominiumDialog"></form-dialog>
    <find-serial-number-dialog ref="findSerialNumberDialog"></find-serial-number-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FormDialog from "../../components/Dialogs/FormDialog";
import FindSerialNumberDialog from "../../components/Dialogs/FindSerialNumberDialog";
import {getNewCondominiumFields} from "../../static/formFields";
import AddressMixin from "../../mixins/AddressMixin";
import UserMixin from "../../mixins/UserMixin";
import IconsMixin from "../../mixins/IconsMixin";

export default ({
  name: "Condomini",
  components: {FormDialog, FindSerialNumberDialog},
  mixins: [AddressMixin, UserMixin, IconsMixin],
  data() {
    return {
      newCondominiumFields: [],
      condominia: [],
      condoAdmin: true,
      query: "",
      activeView: true
    }
  },
  computed: {
    ...mapGetters(["condominiumList", "condominiumAdministratorList", "totalCondominia", "appUser", "isGranted", "currentUser", "appCondominium"])
  },
  methods: {
    ...mapActions(['fetchAllCondominia', "createCondominium", 'fetchAllCondominiumAdministrators', "fetchUser", "fetchAppCondominium"]),
    async submittedCreateCondominium(data) {
      await this.createCondominium({...data});
    },
    emitMyEvent() {
      this.emitter.emit('update-cond', {'condDescription': this.appCondominium.description })
    },
    async condominiumSelected(condo) {
      await this.fetchAppCondominium(condo.id);
      this.emitMyEvent();
      this.$router.push("/dashboard/condominio/" + condo.id);
    },
    async openCreateCondominiumDialog() {
      await this.fetchAllCondominiumAdministrators();
      let condoAdminList = this.condominiumAdministratorList.map(el => {
        return {
          id: el.id,
          label: this.getFullName(el)
        }
      });
      this.newCondominiumFields = getNewCondominiumFields((query) => this.searchCondominiumAdministrators(query), condoAdminList);
      this.$refs.createCondominiumDialog.show();
    },
    openFindSerialNumberDialog() {
      this.$refs.findSerialNumberDialog.show();
    },
    async searchCondominiumAdministrators(query) {
      await this.fetchAllCondominiumAdministrators({s: query, limit: 50});
      return this.condominiumAdministratorList;
    },
    inputSearch() {

    },
    clearInput() {
      this.query = "";
    }
  },
  async created() {
    if (this.isGranted("ROLE_MANAGER")) {
      await this.fetchAllCondominia();
      this.condominia = this.condominiumList;
      this.condoAdmin = false;
    } else {
      await this.fetchUser(this.appUser.id);
      if (this.currentUser.type === "admin") {
        //console.log(this.currentUser)
        this.condominia = [];
        const cond = {};
        this.currentUser.condoAdmin.forEach(c => {
          if (!cond[c.id]) {
            cond[c.id] = c;
          }
        });
        this.currentUser.condoOwner.forEach(c => {
          if (!cond[c.id]) {
            cond[c.id] = c;
          }
        });
        this.currentUser.condoSupplier.forEach(c => {
          if (!cond[c.id]) {
            cond[c.id] = c;
          }
        });
        this.currentUser.condoTechnician.forEach(c => {
          if (!cond[c.id]) {
            cond[c.id] = c;
          }
        });
        //console.log(cond)
        this.condominia = Object.values(cond);
      }
    }

  }
})

</script>

<style scoped>

.color-danger {
  color: #F56C6C
}

.color-success {
  color: #67C23A
}

.color-warning {
  color: #E6A23C
}

</style>
